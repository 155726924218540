import React from 'react'
import Navbar from '../components/Navbar'
import Layout from '../components/Layout'
import '../styles/books.sass'
import Squiggle from '../img/squiggly2.png'
import BuySection from '../components/BuyNow'
import Signature from '../img/signature.png'
import Footer from '../components/Footer'
import MiniMenu from '../components/MiniMenu'
import RuinsBookUK from '../img/ruinsuk.png'
import RuinsBookUS from '../img/ruinsus.png'
import RuinsLetter from '../img/RuinsAuthorLetter.pdf'

export const RuinsPageTemplate = () => (
  <>
    <div>
      <Navbar showSub={false} showMenu={true} bold />
      <MiniMenu hideBookClubGuide={true} />
      <div className="container books">
        <div className="columns">
          <div className="column">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                flexWrap: 'wrap',
              }}
            >
              <img className="madam-book-img" src={RuinsBookUK}></img>
              <img className="madam-book-img" src={RuinsBookUS}></img>
            </div>
            <h1 id="summary" className="text-center">The Ruins</h1>
            <p>
              <div style={{
                fontWeight: 'bold', fontSize: '19px',
              }}>
                With riveting psychological complexity, The Ruins captures the tangled legacy of abuse,
                the glittering allure of the Mediterranean––and the dark shadows that wait beneath the surface of
                both. </div>

              Welcome to the Chateau des Sètes, a jewel of the Cote d’Azur, where long summer days bring ease, glamour,
              and decadence to the holidaymakers who can afford it. Ruby Ashby adores her parents’ house in France, but this August,
              everything feels different. Unexpected guests have descended upon the chateau––friends of her parents, and their
              daughters—and they are keen to enjoy the hot, extravagant summer holiday to its fullest potential. Far from England,
              safe in their wealth and privilege, the adults revel in bad behaviour without consequence, while the girls are treated as
              playthings or abandoned to their own devices. But despite languid days spent poolside and long nights spent drinking, a simmering
              tension is growing between the families, and the sanctuary that Ruby cherishes soon starts to feel like a gilded cage. Over two decades
              later the chateau is for sale, its days of splendour and luxury long gone, leaving behind a terrible history and an ugly legacy. A young widow
              has returned to France, wanting to purchase the chateau, despite her shocking memories of what transpired that fateful summer. But there is
              another person who is equally haunted by the chateau, and who also seeks to reclaim it. Who will set the chateau free––and who will become
              yet another of its victims?
              <br />
            </p>
          </div>
        </div>

        <div className="section section-container">
          <img className="squiggle" src={Squiggle}></img>
        </div>
        <div id="buy">
          <BuySection
            heading={"The Ruins is currently available to pre-order in the UK and US."}
            buyLinkUK={"https://linktr.ee/PhoebeWynne2"}
            buyTextUK={"Pre-order The Ruins"}
          />
        </div>
        <div className="section section-container">
          <img className="squiggle" src={Squiggle}></img>
        </div>
        <h1 className="text-center" id="reviews">reviews</h1>
        <div className="quote-container">
          <div className="quote">
            <div className="quote-content">
              I was completely transported by The Ruins. Phoebe Wynne has evocatively
              rendered a beautiful, doomed French chateau while creating immersive, almost
              dream-like atmospherics. I loved this novel.
            </div>
            <div className="quote-name">Sarah Pearse</div>
            <div className="quote-bio">
              Bestselling author of THE SANATORIUM
            </div>
          </div>
          <div className="quote">
            <div className="quote-content">
              Tense and atmospheric, The Ruins investigates trauma and memory with a knowing, meticulous hand.
              The Chateau des Sètes - in all its crumbling, sinister glory - will imprint on your psyche as if you,
              too, spent a fateful summer there years ago... but it's the young women who call it home that leave
              the mostlasting impression. This is a book that understands the inherent dangers of girlhood, and one
              that believes wholeheartedly in the innate resilience, bravery, and compassion of teenage girls themselves.
            </div>
            <div className="quote-name">Emily Layden</div>
            <div className="quote-bio">Author of ALL GIRLS</div>
          </div>
          <div className="quote">
            <div className="quote-content">
              The French Riviera has never been more captivating or unsettling. A story rich with family tensions and a
              chateau that serves as a gilded cage. The Ruins is both beautifully written and haunting at its core.
              Another stunning novel from Phoebe Wynne.
            </div>
            <div className="quote-name">Shea Ernshaw</div>
            <div className="quote-bio">
              NEW YORK TIMES bestselling author of A HISTORY OF WILD PLACES and THE WICKED DEEP
            </div>
          </div>

          <div className="quote">
            <div className="quote-content">
              Rippling with suspense and gripping from the outset, The Ruins is a powerful feminist novel written with real panache
            </div>
            <div className="quote-name">VICTORIA SELMAN</div>
            <div className="quote-bio">
              Author of TRULY DARKLY DEEPLY
            </div>
          </div>

          <div className="quote">
            <div className="quote-content">
              The Ruins is a raw, unsettling coming-of-age story not for the faint-hearted. Harrowing and compelling,
              this is a story burning with rage and the heat of the male gaze
            </div>
            <div className="quote-name">VIKKI PATIS</div>
            <div className="quote-bio">Author of RETURN TO BLACKWATER HOUSE</div>
          </div>
        </div>
        <div className="section section-container">
          <img className="squiggle" src={Squiggle}></img>
        </div>

        <div className="letter-container" id="letter">
          <h1 className="text-center">a letter to readers</h1>

          <a href={RuinsLetter} target="_blank">
            <h3 className="text-center download-link">Download →</h3>
          </a>

          <p>
            Dear Reader, <br />
            <br />
            For so many, the South of France conjures up images of the Cannes Film Festival,
            60s films starring Brigitte Bardot, or the expat excess of the Fitzgeralds. For me,
            the Riviera runs through my blood and my memory, thanks to every summer spent there
            as a child. The pairing of my French mother and English father ensured that I enjoy
            the two cultures, cherish each of them, but somehow remain a foreigner to both. It’s
            a lonely place to be, but it’s also the perfect vantage point from which to observe.
            Beautiful summers in the Côte d’Azur scorch your memory when they go
            wrong. What seems idyllic and luxurious in The Ruins—wealthy Brits holidaying
            with their friends in a chateau on the Riviera—hides something I recognise: a
            sense of entitlement, one-upmanship concealed by camaraderie, and elaborate displays
            of wealth masking real money worries. In The Ruins, every one of these glitteringly
            grim moments is performed in front of the wide eyes of the children.
            Children see things more simply and more clearly than adults. Children feel their feelings
            in sharper focus than adults. Children grow up and become remembering adults. And by God,
            do they remember.  <br />  <br />
            In The Ruins, the three girls both speak and keep silent in ways that women everywhere will
            recognise in younger versions of themselves. Their thoughts and actions still shock and
            surprise me, though they are entirely of my own creation. But it seems I had to write this
            novel just to rewrite the story I knew as a child, that I know now as a woman. And so
            inevitably, The Ruins strays from real life in one crucial way: the adults are held accountable,
            and punished by the children, ensuring that those that did wrong get
            their just desserts.   <br />  <br />
            Perhaps this is why, as an adult, I’ve been so drawn to the Classics: tragedy, blame, and
            retribution are inextricably linked. As in my debut novel, Madam, shades of the ancient histories
            and mythologies find their way into this contemporary story, reminding us that the past is never far,
            and that we mortals are doomed to repeat the same mistakes, again and again, as the balance of justice hangs over us.
            But what might happen if a few brave girls disrupted that cycle? I hope you’ll discover the answer for
            yourself in The Ruins. <br />
            <br />
            <img className="madam-book-sig" src={Signature}></img>
            <br />
          </p>

        </div>

        <div className="section section-container">
          <img className="squiggle" src={Squiggle}></img>
        </div>
      </div>
    </div>
    <Footer />
  </>
)

const RuinsPage = () => {
  return (
    <Layout>
      <RuinsPageTemplate />
    </Layout>
  )
}

export default RuinsPage
